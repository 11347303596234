import React from "react";
import classes from "../DataProvisionning.module.css";
import {FileUpload} from "primereact/fileupload";
import {isClosed} from "../../../../../../api/dataCollection/dataCollection-status";
import Aux from "../../../../../../hoc/Auxiliary";
import {isAuthorized} from "../../../../../../api/authorization/authorization-service";
import {Button} from "primereact/button";

const Header = () => {
    return (
        <div className="col-12 flex align-items-center justify-content-center">
                <span>
                    <label className={classes.title}>Data provisioning</label>
                </span>
        </div>
    )
}

const FileUploadButton = ({fileUploadRef, getFileContext, dataCollection, statusLV4, uploadBtnDisabled,updateStatus}) => {
    return (
        <div className="col-12 flex align-items-center justify-content-center">
            <FileUpload mode="basic" name="demo[]"
                        ref={fileUploadRef}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.ttl"
                        className={classes.uploadBtn}
                        onSelect={getFileContext}
                        chooseLabel={"Upload data"}
                        disabled={statusLV4 || uploadBtnDisabled || (!isAuthorized("AR08") && isClosed(dataCollection.status))}
            />
            <Button  label="No data" disabled={statusLV4 || uploadBtnDisabled || (!isAuthorized("AR08") && isClosed(dataCollection.status)) } className={classes.noDataBtn} icon="pi pi-times" severity="secondary" outlined onClick={updateStatus} />


        </div>
    )
}
const UploadButton = ({fileUploadRef, getFileContext, dataCollection, statusLV4, uploadBtnDisabled,updateStatus}) => {
    return (
        <Aux>
            <div className="grid">
                <Header/>
                <FileUploadButton fileUploadRef={fileUploadRef}
                                  getFileContext={getFileContext}
                                  dataCollection={dataCollection}
                                  statusLV4={statusLV4}
                                  updateStatus={updateStatus}
                                  uploadBtnDisabled={uploadBtnDisabled}

                />

                <div className="col-12 flex align-items-center justify-content-center mt-0 mb-0 pt-0 pb-0">
                    <span className="pi pi-info-circle pl-1"/>
                    <small className={classes.small}>Support XLSX or TTL files</small>
                </div>
            </div>
        </Aux>
    )
}
export default UploadButton;
