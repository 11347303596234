import React, {useEffect, useRef, useState} from "react";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputText} from "primereact/inputtext";
import classes from "./SparqlQuery.module.css";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";
import {Divider} from "primereact/divider";
import {Checkbox} from "primereact/checkbox";
import SparqlParameter from "./Parameter/SparqlParameter";
import {generateRandomKey} from "../../../../api/utils/Random";
import {adaptQueryName, checkQuery, defaultParameters, sparqlExtensions} from "../../../../api/utils/SparqlQueries";
import {showError} from "../../../../api/notification/ToastManager";
import Auxiliary from "../../../../hoc/Auxiliary";
import EditQueryDialog from "./EditQueryDialog";
import {removeNonUtf8} from "../../../../api/utils/Strings";


const SparqlQuery = ({queryIndex, allQueries, updateQueries, deleteCurrentQuery, updateQueryButtons, readOnly}) => {
    const fileUploadRef = useRef(null);
    const [currentQuery, setCurrentQuery] = useState({...allQueries[queryIndex]})
    const [errorMessage, setErrorMessage] = useState('')
    const [fileName, setFileName] = useState("Choose File");
    const [isEditQueryVisible, setIsEditQueryVisible] = useState(false);
    const onDefaultParamsChange = (e) => {
        let sp = [...currentQuery.parameters];
        if (e.checked) {
            sp.push(e.value);
        } else {
            sp = sp.filter(p => p.key !== e.value.key);
        }
        setCurrentQuery({...currentQuery, parameters: [...sp]});

    };
    const close = () => {
        setIsEditQueryVisible(false)
    }
    useEffect(() => {
        setCurrentQuery(allQueries[queryIndex]);
        if (allQueries[queryIndex].sparqlParameters.length === 0) {
            setCurrentQuery({
                ...currentQuery,
                sparqlParameters: [{name: '', key: generateRandomKey(), properties: {}}]
            });
        }
    }, [allQueries])

    const add = () => {
        const arr = [...currentQuery.sparqlParameters, {name: '', key: generateRandomKey(), properties: {}}]
        setCurrentQuery({...currentQuery, sparqlParameters: [...arr]});
    }

    const drop = (id) => {
        const arr = [...currentQuery.sparqlParameters];
        if (arr.length === 1) {
            setCurrentQuery({
                ...currentQuery,
                sparqlParameters: [{name: '', key: generateRandomKey(), properties: {}}]
            });
        } else {
            setCurrentQuery({...currentQuery, sparqlParameters: [...arr.filter((e, i) => i !== id)]});
        }
    }
    const warningMsg="This query currently have "+currentQuery['executions']?.length + " executions !"
    const warningDeleteQuery= currentQuery['executions']?.length!==0 && currentQuery['executions']?.length!=undefined?warningMsg:"";

    const updateParameter = (id, name, properties) => {
        const arr = [...currentQuery.sparqlParameters];
        arr[id].name = name;
        arr[id].properties = {...properties}
        setCurrentQuery({...currentQuery, sparqlParameters: [...arr]})
    }

    const updateCurrentQuery = () => {
        const arr = [...allQueries]
        arr[queryIndex] = {...currentQuery};
        updateQueries([...arr])
    }

    useEffect(() => {
        const message = checkQuery(currentQuery, allQueries);
        setErrorMessage(message)
        const arr = [...allQueries]
        arr[queryIndex] = {...currentQuery};
        updateQueryButtons(arr)
    }, [currentQuery])

    const getFileContext = async (event) => {
        const name = event.files[0].name
        const tm = name.split('.')
        if (sparqlExtensions.includes(tm[tm.length - 1])) {
            const reader = new FileReader()
            reader.onload = async (e) => {
                const text = (e.target.result)
                checkFileContent(text, name)
            };
            reader.readAsText(event.files[0], 'UTF-8')
        } else {
            clearFileContextWithErrorToast("Unsupported file extension", "The Query file extension is not valid. Possible extensions : (" + sparqlExtensions.join(" ,") + " ).")
        }
    }

    const checkFileContent = (text, name) => {
        if (text.length > 0) {
            setCurrentQuery({...currentQuery, content: removeNonUtf8(text)})
            setFileName(name)
            fileUploadRef.current.clear();
        } else {
            clearFileContextWithErrorToast("Empty file content", "The Query file content is empty")
        }
    }

    const clearFileContextWithErrorToast = (summary, message) => {
        setFileName("Choose File")
        fileUploadRef.current.clear();
        showError(summary, message)

    }

    const handleInputChange = (value) => {
        const filteredValue = value.replace(/[^a-zA-Z0-9_-]/g, '');
        setCurrentQuery({
            ...currentQuery,
            name: adaptQueryName(filteredValue)
        })
    };

    return (
        <div onMouseLeave={updateCurrentQuery}>
            <br/>
            <div className="grid">
                <div className="col-12">
                    <Accordion activeIndex={readOnly ? -1 : 0}>
                        <AccordionTab header={`${currentQuery.name}`}>
                            <div className="grid">
                                <div className="col-6">
                                    <div className="grid">
                                        <div className="col-12">
                                            <p><b>Name</b></p>
                                        </div>
                                        <div className="col-12">
                                            <div className="grid ">
                                                <div className="col-8 align-self-center">
                                                    <InputText name="name"
                                                               value={`${currentQuery.name}`}
                                                               className={classes.inputStyle}
                                                               placeholder={"Your Query name ..."}
                                                               onChange={event => {
                                                                   handleInputChange(event.target.value);
                                                               }}
                                                               disabled={readOnly}
                                                    />
                                                </div>
                                                {
                                                    readOnly && <div className="col-1 align-items-center">
                                                        <Button type="button"
                                                                className={classes.customButton}
                                                                tooltip={"Edit Query"}
                                                                icon="pi pi-pencil"
                                                                onClick={() => {
                                                                    setIsEditQueryVisible(true)
                                                                }}
                                                        />
                                                    </div>
                                                }

                                                <div className="col-3">
                                                    <FileUpload mode="basic" name="demo[]"
                                                                ref={fileUploadRef}
                                                                tooltip={"upload new Query"}
                                                                accept="application/text"
                                                                className={classes.uploadBtn}
                                                                onSelect={getFileContext}
                                                                chooseLabel={fileName}
                                                    />

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <p><b>Description</b></p>
                                    <InputTextarea name="description"
                                                   rows={5}
                                                   cols={30}
                                                   autoResize
                                                   className={classes.inputStyle}
                                                   value={`${currentQuery.description}`}
                                                   placeholder={"Please provide a description of this query to help users ..."}
                                                   onChange={event => setCurrentQuery({
                                                       ...currentQuery,
                                                       description: event.target.value
                                                   })}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <Divider layout="vertical" className="hidden md:flex"/>
                                </div>
                                <div className="col-5">
                                    <p><b>Parameters</b></p>
                                    <div className="flex flex-column gap-3">
                                        {defaultParameters.map((param) => {
                                            return (
                                                <div key={param.key} className="flex align-items-center">
                                                    <Checkbox inputId={param.key} name="category" value={param}
                                                              onChange={onDefaultParamsChange}
                                                              checked={currentQuery.parameters.some((item) => item.key === param.key)}

                                                    />
                                                    <label htmlFor={param.key} className="ml-2">
                                                        {param.name} : Query will be executed for specific {param.name}.
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {currentQuery.sparqlParameters.length > 0
                                        ?
                                        (<p><b>SPARQL Parameters</b></p>)
                                        : null}

                                    <div className="flex flex-column gap-3">
                                        {currentQuery.sparqlParameters.map((param, index) => {
                                            return (
                                                <div key={param.key} className="flex align-items-center">
                                                    <SparqlParameter param={param}
                                                                     allParameters={currentQuery.sparqlParameters}
                                                                     addNewParameter={add}
                                                                     deleteParameter={() => drop(index)}
                                                                     index={index}
                                                                     updateParameter={(n, p) => updateParameter(index, n, p)}
                                                                     readOnly={readOnly}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {currentQuery.sparqlParameters.length > 0
                                        ? <Auxiliary>
                                            <span className="pi pi-info-circle"/><small> Default is <b
                                            style={{color: "red"}}>Resource</b> , click on ✏️ to edit
                                            it.</small>
                                            <br/>
                                            <span className="pi pi-info-circle"/><small> Parameter name can only contain
                                            letters, numbers and underscores</small>

                                        </Auxiliary>
                                        : null}

                                </div>
                                <div className="col-12">
                                    <Button type="button" className={classes.customButton} icon=" pi pi-trash"
                                            onClick={() => {
                                                setFileName("Choose File")
                                                deleteCurrentQuery()
                                            }}
                                            tooltip={warningDeleteQuery} tooltipOptions={{ position: 'bottom'}}
                                    />
                                    {errorMessage === '' ? null :
                                        <small style={{whiteSpace: "pre-wrap"}}>{errorMessage}</small>}
                                </div>
                            </div>
                        </AccordionTab>

                    </Accordion>
                </div>
            </div>
            <EditQueryDialog currentQuery={currentQuery}
                             isEditQueryVisible={isEditQueryVisible}
                             changeDesc={(event) => {
                                 currentQuery.content = removeNonUtf8(event.target.value);
                                 setCurrentQuery(currentQuery);
                             }}
                             closeDialog={(_) => {
                                 close();
                             }}/>
        </div>
    )
}

export default SparqlQuery;
