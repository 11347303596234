import keycloak from "../../keycloak";
import axios from "axios";
import {showError, showInfo, showSuccess} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export const saveDataCollection = async (data) => {

    let status = 408;
    let content="";
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/", data, {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }

        }).then(response => {
            if (response.data.status === 200) {
               if(data.status==="PUBLISHED" && data.dataCollection.copiedFrom?.reuseDataOption==="Reuse_data_and_revalidate_all_data"||data.dataCollection.copiedFrom?.reuseDataOption==="Reuse_data_and_validation_status" )
               {
                   showSuccess("Data collection " + data.dataCollection.name + " is created");
               }
               else
               {
                   showSuccess("data collection " + data.dataCollection.name + data.successMsg);
               }

            } else if (response.data.status === 409) {
                showError("Error when creating DataCollection: " + response.data.message);
            } else {
                showError("Error", "An error was occurred when saving dataCollection");
            }
            status = response.data.status;
            content=response.data.content;
        })
    } catch (e) {
        showError("Error", "An error was occurred when saving data collection");
    }
    return {status:status, content:content};
}

export async function getErrorsByDataCollectionsId(id) {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/errors/" + id, header)
            .then(res => {
                response.content = res.data.content;
                response.status = res.data.status;
            })
        return response
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}

export async function solveError(params) {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }

    let result = {}

    try {
        await axios.post(global.config.BACK_URL + "dataCollection/errors", params, header)
            .then(res => {
                result.status = res.data.status;
                result.content = res.data.content;
            })
    } catch (e) {
        result.status = 500;
    }

    return result;
}

export async function getDataCollectionsGeneralInfoByStatus(status) {
    let response = {}
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/list/generalInfo/status/" + status, header)
            .then(res => {
                response = res.data.content
            })
        return response
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list of data collection");
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
}

/**
 * Retrieve all DataCollections details by excluded status
 * @param excludeStatus excluded status
 * @param generalInfo if true , return list of DataCollectionGeneralInfoDTO, else return list of DataCollectionDTO
 * */
export const getAllDataCollectionDetailsByExcludedStatus = async (excludeStatus, generalInfo) => {
    const url = generalInfo
        ? "dataCollection/list/generalInfo/excludeStatus"
        : "dataCollection/list/details/excludeStatus";
    let response = []
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        await axios.post(global.config.BACK_URL + url , excludeStatus, header)
            .then(res => {
                Array.prototype.push.apply(response, res.data.content)
            })
    } catch (e) {
        showError("Error", "An error was occurred when retrieving list of data collections");
    }
    return response;
}

export async function getDataCollectionDetails(dataCollectionId) {
    let data = [];
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/" + dataCollectionId + "/details", {
            headers: {'Authorization': 'Bearer ' + keycloak.token}
        }).then(response => {
            if (response.data.status === 200) {
                data = response.data.content;
            }
        })

        return data;
    } catch (e) {
        showError("Error", "An error was occurred when retrieving data collection details");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}


export async function getWriteAccessMarketNodesDetailsLight(dataCollectionId) {
    let data = [];
    try {
        await axios.get(global.config.BACK_URL + "dataCollection/" + dataCollectionId + "/writeAccessMarketNodesDetailsLight", {
            headers: {'Authorization': 'Bearer ' + keycloak.token}
        }).then(response => {
            if (response.data.status === 200) {
                data = response.data.content;
            }
        })

        return data;
    } catch (e) {
        showError("Error", "An error was occurred when retrieving data collection details");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function findDataCollectionById(id) {
    if (!id) {
        showError("DataCollection not found", "Could not find a dataCollection with ID=" + id);
        return {
            status: 404,
            content: null
        }
    }
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        let data = {};
        await axios.get(global.config.BACK_URL + "dataCollection/" + id, header)
            .then(response => {
                if (response.data.status === 200) {
                    data = response.data.content;

                } else if (response.data.status === 204) {
                    showError("Error when finding DataCollection: " + response.data.message);
                } else {
                    showError("Error", "An error was occurred when finding dataCollection");
                }
            })
        return data;
    } catch (e) {
        showError("Error", "An error was occurred when finding data collection");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function findDataCollectionByParticipant(generalInfo) {
    const url = generalInfo
        ? "dataCollection/list/generalInfo/byParticipant"
        : "dataCollection/list/details/byParticipant"
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let data = [];
    try {
        await axios.post(global.config.BACK_URL + url, {
            participants: keycloak.idTokenParsed.fullPathGroup
        }, header)
            .then(response => {
                if (response.data.status === 200) {
                    data = [...response.data.content];
                } else if (response.data.status === 204) {
                    showError("An error was occurred when finding dataCollection " + response.data.message);
                } else {
                    showError("Error", "An error was occurred when finding dataCollection");
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when finding dataCollection");
    }
    return data;
}

export async function saveDataCollectionInES(params) {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let err = {isError: false, isTimeOut: false};
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/persist", params, header)
            .then(response => {
                if (response.data.status === 200) {
                    err.isError = false
                    showSuccess("Data has been saved in database");
                } else if (response.data.status === 500) {
                    err.isError = true
                    err.message = "Error when saving DataCollection in database: " + response.data.message;
                } else {
                    err.isError = true
                    showError("Error", "An error was occurred when saving data for visualisation in database");
                }
            })
    } catch (e) {
        if (e.response.status === 504) {
            showInfo("Info", "The persist might take several minutes")
            err.isTimeOut = true
        } else {
            err.isError = true
            err.message = "Error when saving DataCollection in database: " + e.response.data.message;
        }
    }

    return err;
}

export async function sendMCInvitation(dataCollectionId) {
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let data = null;
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/sendMCInvitation", {id:dataCollectionId}, header)
            .then(response => {
                if (response.data.status === 200) {
                    data = response;
                    showSuccess("Manual check invitation sent successfully");
                } else {
                    showError("Error", "An error was occurred when sending manual check invitation");
                }
            })
        return data;
    } catch (e) {
        showError("Error", "An error was occurred when sending manual check invitation ");
        return {
            status: e.response.data.status,
            content: null
        }
    }

}

export const reopenDataCollection = async (dataCollection,  selectedMarketNodes, newDeadline, notificationMsg) => {
    let data;

    try {
        await axios.post(global.config.BACK_URL + "dataCollection/open",
            {
                dataCollection: dataCollection,
                selectedMarketNodes: selectedMarketNodes,
                newDeadline: newDeadline,
                notificationMsg: notificationMsg
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                data = response.status;
                if (response.status === 200) {
                    showSuccess("Data collection '" + dataCollection.name + "' is successfully reopened");
                } else {
                    showError("Error", "An error was occurred when reopening data collection " + dataCollection.name);
                }
            })
        return data === 200;
    } catch (e) {
        if (e.response.status === 504) {
            showInfo("Info", "Reopening Data collection might take several minutes")
        } else {
            showError("Error", "An error was occurred when reopening Data Collection");
        }
        return {
            status: e.response.data.status,
            content: null
        }
    }
}
export const editPublishedDataCollection = async (dataCollection, selectedParticipants, newDeadline, notificationMsg,modelId) => {
    let data;
        await axios.post(global.config.BACK_URL + "dataCollection/edit",
            {
                dataCollection: dataCollection,
                selectedParticipants: selectedParticipants,
                newDeadline: newDeadline,
                notificationMsg: notificationMsg,
                modelId:modelId
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                data = response.status;
                if (response.status === 200) {
                    showSuccess("Data collection '" + dataCollection.name + "' is successfully edited");
                } else {
                    showError("Error", "An error was occurred when editing data collection " + dataCollection.name);
                }
            }).catch((error)=>{
                data = error.response.status;
                if (error.response.status === 504) {
                    showInfo("Info", "Editing Data collection might take several minutes")
                } else {
                    showError("Error", "An error was occurred when editing Data Collection");
                }
            })
        return data === 200;
}
export const closeDataCollection = async (dataCollection, closedStatus) => {
    let data;
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/close",
            {
                dataCollection: dataCollection,
                status: closedStatus
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                data = response.status;
                if (response.status === 200) {
                    showSuccess("Data collection '" + dataCollection.name + "' is successfully closed");
                } else {
                    showError("Error", "An error was occurred when closing data collection " + dataCollection.name);
                }
            })
        return data === 200;
    } catch (e) {
        showError("Error", "An error was occurred when closing dataCollection");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}


export async function deleteDataCollectionDraft(dcData) {
    try {
        let header = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        }
        let res = {};
        await axios.post(global.config.BACK_URL + "dataCollection/delete", dcData, header)
            .then(response => {
                res = response;
                if (response.status !== 200 && response.data.status !== 204) {
                    showError("Error", "An error was occurred.");
                }
            })
        return res;
    } catch (e) {
        showError("Error", "An error was occurred.");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function deletePublishedDataCollection(dcData) {
    try {
        let header = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        }
        let res = {};
        await axios.post(global.config.BACK_URL + "dataCollection/deletePublished", dcData, header)
            .then(response => {
                res = response;
                if (response.status !== 200 && response.data.status !== 204) {
                    showError("Error", "An error was occurred.");
                }
            })
        return res;
    } catch (e) {
        showError("Error", "An error was occurred.");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function getDashboardsByDC(dataCollectionNameByParticipants) {
    let data;
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/dashboard/list", dataCollectionNameByParticipants,
            {
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token
                }
            })
            .then(response => {
                if (response.status === 200) {
                    data = response;
                } else {
                    data = {
                        status: response.status,
                        content: null
                    }
                }
            })
        return data;
    } catch (e) {
        showError("Error", "An error was occurred when retrieving dashboard for data collection  " + dataCollectionNameByParticipants.dataCollectionName);
        return {
            status: e.response.data.status,
            content: null
        }
    }


}

export async function shareDashboard(data) {

    let res;
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/share", data, {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token,
                'kbn-xsrf': 'true' 
            }

        }).then(response => {
            if (response.data.status === 200) {
                showSuccess("shared dashboard " + data.dashboardInfo.title + " with all participants");

            } else if (response.data.status === 409) {
                showError("Error when sharing dashboard: " + response.data.message);
            } else {
                showError("Error", "An error was occurred when sharing dashboard");
            }
            res = response.data.status;
        })
        return res;
    } catch (e) {
        showError("Error", "An error was occurred when saving data collection");
        res = {
            status: e.response.data.status,
            content: null
        }
        return res;
    }
}

export async function updateMarketNodeStatus(dcId,marketNodeId,statusCode,context) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        var marketNodeData={
            "dcId":dcId,
            "marketNodeId":marketNodeId,
            "statusCode":statusCode,
            "context":context
        }
        await axios.post(global.config.BACK_URL + "dataCollection/marketnode/setNoData",marketNodeData , header)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
            content: null
        }
        return response;
    }
    return response;
}


export async function getDCDetailsById(idDc) {
    if (!idDc) {
        showError("DataCollection not found", "Could not find a dataCollection with ID=" + idDc);
        return {
            status: 404,
            content: null
        }
    }
    let header = {
        headers: {
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        let data = {};
        await axios.get(global.config.BACK_URL + "dataCollection/list/details/" + idDc, header)
            .then(response => {
                if (response.data.status === 200) {
                    data = response.data.content;

                } else if (response.data.status === 204) {
                    showError("Error when finding DataCollection: " + response.data.message);
                } else {
                    showError("Error", "An error was occurred when finding dataCollection");
                }
            })
        return data;
    } catch (e) {
        showError("Error", "An error was occurred when finding data collection");
        return {
            status: e.response.data.status,
            content: null
        }
    }


}

