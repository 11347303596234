import axios from 'axios';
import keycloak from "../../keycloak";
import {showError, showInfo, showSuccess} from "../notification/ToastManager";
axios.defaults.withCredentials = true
export async function findByDCAndMarketNode(dataCollection, marketNode) {

    if (!dataCollection?.id || !marketNode?.id) {
        return {
            status: 404,
            content: null
        }
    }
    let header = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        }
    ;
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/marketnode/details", {
            "dataCollectionId": dataCollection?.id,
            "marketNodeId": marketNode?.id
        }, header)
            .then(res => {
                if (res.status === 200) {
                    response.status = res.data.status;
                    response.content = res.data.content;
                }
            })
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
    return response;
}


export async function getLevel5ValidationStatus(dataCollection,marketNode) {
    let header = {
            headers: {
                'Authorization': 'Bearer ' + keycloak.token
            }
        }
    ;
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "dataCollection/level5/isValide", {
            "dataCollectionId": dataCollection?.id,
            "marketNodeId": marketNode?.id
        }, header)
            .then(res => {
                if (res.status === 200) {
                    response.status = res.data.status;
                    response.content = res.data.content;
                }
            })
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
    return response;
}


export async function validateCategory(params) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "category/validate", params, header)
            .then(res => {
                response.status = res.data.status;
                if (res.status === 200) {
                    if(res.data.status === 200){
                        response.content = res.data.content;
                    } else if(res.data.status === 202){
                        response.content = res.data.content;
                    }
                } else if (res.status === 504) {
                    showInfo("Info", "The validation might take several minutes")
                } else {
                    response.content = {};
                    showError("Error", "An error was occurred when executing validation")
                }
            })
    } catch (e) {
        if (e.response.status === 504) {
            showInfo("Info", "The validation might take several minutes")
        } else {
            showError("Error", "An error was occurred when executing validation")
        }
        response.status = e.response.status;
        response.content = {}
    }
    return response;
}

export async function findValidationResultDetails(validationResultId) {
    let header = {
        headers: {
            'Content-Type': 'text/plain',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.get(global.config.BACK_URL + "category/validation/details/" + validationResultId)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
    return response;
}

export async function publishData(params, id, name) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    try {
        await axios.post(global.config.BACK_URL + "category/publish", {
            marketNodeCategoryDTO: params,
            dataCollectionId: id,
            dataCollectionName: name
        }, header)
            .then(res => {
                if (res.status === 200) {
                } else {
                    showError("Error", "An error was occurred when publishing data")
                }
            })
    } catch (e) {
        showError("Error", "An error was occurred when publishing data")
    }
}

export async function persistData(params, id, name) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "category/persist", {
            marketNodeCategoryDTO: params,
            dataCollectionId: id,
            dataCollectionName: name
        }, header)
            .then(res => {

                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;

                } else {
                    response.status = res.status
                }
            })
    } catch (e) {
        response = {
            status: e.response.status,
        }
        return response;
    }
    return response;
}

export async function getValidationProgressStatus(dataCollectionName, categoryName, marketNodeName) {
    let header = {
        headers: {
            'Content-Type': 'text/plain',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.get(global.config.BACK_URL + "category/validation/status/" + dataCollectionName + "/" + categoryName + "/" + marketNodeName, header)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
    return response;
}

export async function killValidationProcessApi(validationId) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "category/validate/kill/", validationId, header)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
            status: e.response.data.status,
            content: null
        }
        return response;
    }
    return response;
}

export async function getWriteAccessMarketNodeDetails(dcId, marketNodeId) {
    let data = [];
    try {
        await axios.get(global.config.BACK_URL + "category/marketnode/writeAccessMarketNodeDetails/" + dcId + "/" + marketNodeId, {
            headers: {'Authorization': 'Bearer ' + keycloak.token}
        }).then(response => {
            if (response.data.status === 200) {
                data = response.data.content;
            }
        })

        return data;
    } catch (e) {
        showError("Error", "An error was occurred when retrieving marketNode details");
        return {
            status: e.response.data.status,
            content: null
        }
    }
}

export async function setNoDataToProvide(dataCollectionName,dcId,marketNodeId,marketNodeName,categoryName,dcMarketNodeId) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {

        var marketNodeData={
            "dataCollectionName":dataCollectionName,
            "dcId":dcId,
            "marketNodeId":marketNodeId,
            "marketNodeName":marketNodeName,
            "category":categoryName,
            "dcMarketNodeId":dcMarketNodeId

        }
        await axios.post(global.config.BACK_URL + "category/marketnode/noData",marketNodeData , header)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
            content: null
        }
        return response;
    }
    return response;
}
export async function updateMarketNodeCategoryStatus(dcMarketNodeId,statusCode,StatusContext) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    }
    let response = {};
    try {
        var marketNodeData={
            "dcMarketNodeId":dcMarketNodeId,
            "statusCode":statusCode,
            "context":StatusContext
        }
        await axios.post(global.config.BACK_URL + "category/marketnode/updateStatus",marketNodeData , header)
            .then(res => {
                if (res.status === 200) {
                    response.content = res.data.content;
                    response.status = 200;
                }
            })
    } catch (e) {
        response = {
             content: null
        }
        return response;
    }
    return response;
}
export async function validateNoData(params) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + keycloak.token
        }
    };
    let response = {};
    try {
        await axios.post(global.config.BACK_URL + "category/marketnode/noData/validate", params, header)
            .then(res => {
                if (res.status === 200) {
                    response.status = res.status;
                    response.content = res.data;
                    showSuccess("Success", `Validation is completed.`);
                }
            });
    } catch (e) {
        response = {
            status: e.response?.status || 500,
            content: null
        };
        showError("Error", "An error occurred while validating Data.");
    }
    return response;
}
