import React, {useEffect, useState} from "react";
import Auxiliary from "../../../../../../hoc/Auxiliary";
import {DataTable} from "primereact/datatable";
import TemplatePaginator from "../../../../../commun/TemplatePaginator/TemplatePaginator";
import {Column} from "primereact/column";
import {formatDate} from "../../../../../../api/utils/DateFormatter";
import LongTextColumn from "../../../../../commun/Table/LongTextColumn/LongTextColumn";
import ActionButtonsTemplate from "../QueryExecutionsList/ActionBody/ActionButtonsTemplate";
import {sortByDate} from "../../../../../../api/utils/sortArrays";
import {Tooltip} from 'primereact/tooltip';


const formatExecutionsData = (data) => {
    const newData = sortByDate(data, "createdIn");
    newData.map(e => {
        if (e.createdIn !== null && !e.createdIn.includes("/")) {
            e.createdIn = formatDate(new Date(Date.parse(e.createdIn)));
        }
    })
    return newData
}

const QueryExecutionsList = (props) => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [executions,setExecutions]= useState(formatExecutionsData(props.query["executions"]));
    const [idDeleting, setIdDeleting] = useState([]);

    useEffect(() => {
        setExecutions(formatExecutionsData(props.query["executions"]))
    }, [props.query]);

    const isSelectable = (id) => !idDeleting.includes(id);
    const rowClassName = (data) => {
        return isSelectable(data.id) ? '' : 'p-disabled'
    };
    const descriptionBody = (row) => (<LongTextColumn row={row} fieldName={"parametersDescription"} idField={"id"}/>)


    const actionsBodyTemplate = (row) => (
        idDeleting.includes(row.id) ?
            <div className="flex justify-content-between">
                <span className="vertical-align-middle">Deleting</span>
                <i className="pi pi-spin pi-spinner" style={{fontSize: '1.2rem',padding:'0.15rem'}}></i></div> :
        <ActionButtonsTemplate rowData={row} queryName={props.query.name} setIdDeleting={setIdDeleting}  setExecutions={setExecutions} idDeleting={idDeleting} executions={executions} dcId={props.query.dataCollection.id}/>)

    const nameColumnStructure = (r) => {
        if (r.status.code === "EXECUTION_FAILED" || r.status.code === "EXECUTION_FAILED_WITH_ERROR") {
            return (
                <div className="grid">
                    <div className="col-10 pb-0">{r.name}</div>
                    <div className="col-1 pb-0">
                        <Tooltip target=".custom-target-icon"/>
                        {r.status.code === "EXECUTION_FAILED" ?
                            <i className="custom-target-icon pi pi-exclamation-triangle"
                               data-pr-tooltip={r.status.name}
                               data-pr-position="right"
                               data-pr-at="right+5 top"
                               data-pr-my="left center-2"
                               style={{color: "#bf6c06", cursor: 'pointer'}}/> : null}
                        {r.status.code === "EXECUTION_FAILED_WITH_ERROR" ?
                            <i className="custom-target-icon pi pi-info-circle"
                               data-pr-tooltip={r.status.name}
                               data-pr-position="right"
                               data-pr-at="right+5 top"
                               data-pr-my="left center-2"
                               style={{color: "darkred", cursor: 'pointer'}}/> : null}
                    </div>
                </div>
            )
        } else {
            return r.name;
        }
    }

    const onCustomPage = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    }
    return (
        <Auxiliary>
            <DataTable value={[...executions]}
                       size="small"
                       resizableColumns
                       stripedRows
                       showGridlines
                       paginator paginatorTemplate={TemplatePaginator}
                       first={first} rows={rows} onPage={onCustomPage}
                       paginatorClassName="justify-content-end" className="mt-6"
                       rowClassName={rowClassName}>
                <Column
                    field="name"
                    body={(r) => nameColumnStructure(r)}
                    style={{minWidth: "100px", whiteSpace: 'normal', wordWrap: 'break-word'}}
                    sortable
                    header="Name"
                    filter/>
                <Column field="createdIn" header="Executed In" style={{width: "100px"}}/>
                <Column field="executedBy" header="Executed By" style={{minWidth: "100px"}}/>
                <Column field="parametersDescription" body={descriptionBody}
                        header="Parameters" />
                <Column header="Actions" body={actionsBodyTemplate} style={{width:"150px"}} />
            </DataTable>
        </Auxiliary>
    )
}
export default QueryExecutionsList;
