import React, {useCallback, useEffect, useState} from "react";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {normalizePathItem} from "../../../BreadCrumb/Breadcrumb";
import {canUploadData} from "../../../../api/authorization/rules";
import {showError} from "../../../../api/notification/ToastManager";

const EditButton = (rowData) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    /**
     * Navigate to Market node details ( uploadData screen )
     * */
    const navigateToUploadData = useCallback(() => {

            const dc = normalizePathItem(rowData.dataCollection?.name);
            const mn = normalizePathItem(rowData.marketNodeData?.marketNode?.name);
            if (dc && mn) {
                navigate("/data_collections/" + dc + "/" + mn, {
                    state: {
                        dataCollection: {
                            name: rowData.dataCollection.name,
                            id: rowData.dataCollection.id,
                            status: rowData.dataCollection.status,
                            modelPath: rowData.dataCollection.model.path,
                            copiedFrom: rowData.dataCollection.copiedFrom

                        }, marketNode: rowData.marketNodeData.marketNode
                        , dcMarketNodeId: rowData.marketNodeData.id
                    }
                })
            } else {
                showError("Error", "Could not find a dataCollection '" + rowData.dataCollection?.name + "' and market node '" + rowData.rowData?.marketNode?.name + "'")
                navigate("/data_collections")
            }
        }, [rowData]
    )
    useEffect(() => {

        rowData = rowData.rowData;
        setIsVisible(canUploadData(rowData.dataCollection.participants, rowData.marketNodeData.marketNode));

    }, [rowData])


    if (isVisible) {
        return (
            <Button icon="pi pi-angle-right"
                    tooltip="Edit"
                    tooltipOptions={{position: 'top', showOnDisabled: true}}
                    className=" p-button-rounded-sm p-button-text"
                    onClick={navigateToUploadData}
                    disabled={isDisabled}
                    style={{height: '1.5rem', width: '2rem', color: '#487c84'}}
            />
        )
    } else return null;

}

export default EditButton;
