import React, {useState} from "react";
import Aux from "../../../../../../hoc/Auxiliary";
import {killValidationProcessApi} from "../../../../../../api/Category/category-service";
import {showError, showSuccess} from "../../../../../../api/notification/ToastManager";
import {Button} from "primereact/button";
import classes from "./LoadingSpinner.module.css"
import {ConfirmDialog} from "primereact/confirmdialog";
import {ProgressSpinner} from "primereact/progressspinner";

const LoadingSpinner = (props) => {
    const [visible, setVisible] = useState(false);

    const accept = async () => {

        await killValidationProcessApi(props.validationId).then(async result => {
            props.setLoading(false);
            props.setLoadingLevel3(false);
            props.onFileUpload(false);
            props.setUploadBtnDisabled(false);
            if (result !== null) {
                props.setFilePath(result.content.filePath)
                props.setStatus(result.content.status)
                props.setSummary(result.content.validationSummary)
                showSuccess("Success", `Validation process shutdown.`)

            } else {
                showError("Error", `An error occurs during the validation process shutdown.`);
            }

        });

    }

    const reject = () => {
        setVisible(false)
    }

    const content = props.statusLevel4
        ? (
            <Aux>
                <div className="flex align-items-center justify-content-center">
                    <div className="grid">
                        <div className="col-12">
                            <label className="flex align-items-center justify-content-center"><b> {props.crossValidationMsg}</b></label>
                        </div>
                        <div className="col-12 flex align-items-center justify-content-center">
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             strokeWidth="3"
                                             fill="var(--surface-ground)"
                                             className={classes.spinner}
                                             animationDuration="1s"/>
                        </div>
                    </div>
                </div>
            </Aux>
        )
        : (
            <Aux>
                <ConfirmDialog style={{zIndex: 999}}
                               visible={visible}
                               message={"Are you sure you want to interrupt validation process ?"}
                               header="Confirmation"
                               icon="pi pi-exclamation-triangle"
                               acceptClassName={classes.acceptDelete}
                               rejectClassName={classes.rejectDelete}
                               closable={false}
                               accept={accept}
                               reject={reject}/>
                <div className="flex align-items-center justify-content-center">
                    <Button className={classes.progressBtn}
                            aria-controls={visible ? 'dlg_confirmation' : null} aria-expanded={visible}
                            label="Stop validation"
                            onClick={(e) => setVisible(true)}
                            icon={<i className="pi pi-spin pi-spinner mr-1" style={{fontSize: '1rem'}}/>}
                            iconPos="left"/>


                </div>
            </Aux>

        )

    return (
        <Aux>
            {content}
        </Aux>)

}
export default LoadingSpinner;
