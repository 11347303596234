import React, {useEffect, useState} from "react";
import classes from "./DataValidation.module.css"
import {
    findValidationResultDetails,
    getValidationProgressStatus,
    validateCategory
} from "../../../../../api/Category/category-service";
import {showError, showSuccess} from "../../../../../api/notification/ToastManager";
import {isLevel4ButtonDisabled} from "../../../../../api/MarketNode/marketNode-status";
import ValidationReport from "./Report/ValidationReport";
import LoadingSpinner from "./Spinner/LoadingSpinner";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import Aux from "../../../../../hoc/Auxiliary";
import {Divider} from 'primereact/divider';
import {StatusCode} from "../../../../../entities/StatusContext";
import {Tooltip} from 'primereact/tooltip';

const DataValidation = (props) => {
    const [filePath, setFilePath] = useState(props.categoryDetails['validationDTO'].filePath)
    const [validationWaiting, setValidationWaiting] = useState(props.categoryDetails['validationWaiting'])
    const [summary, setSummary] = useState(`${props.categoryDetails['validationDTO']['validationSummary']}`)
    const [btnStyle, setBtnStyle] = useState(`${classes.downloadBtnSuccess}`);
    const [status, setStatus] = useState('');
    const [isPopupValidationDetailsVisible, setIsPopupValidationDetailsVisible] = useState(false)
    const [sheetList, setSheetList] = useState([]);
    const [refreshIcon, setRefreshIcon] = useState("pi pi-sync");
    const [validationMsgStyle, setValidationMsgStyle] = useState(`${classes.blackText}`);
    useEffect(() => {
        const res = props.categoryDetails['validationDTO'];
        setValidationWaiting(props.categoryDetails['validationWaiting'])
        setValidationMsgStyle(classes.blackText)
        if (res.status.code !== props.status.code) {
            props.setStatus(res.status)
        }
        if (res.status.code !== "VALIDATION_IN_PROGRESS") {
            props.onFileUpload(false);
            props.setUploadBtnDisabled(false);
        }
        if (res.status.code === "VALIDATION_SUCCESS") {
            setBtnStyle(classes.downloadBtnSuccess)
            setStatus("_ACCEPTED")
        }
        if (res.status.code === "VALIDATION_FAILED") {
            setBtnStyle(classes.downloadBtnFailed)
            setStatus("_REJECTED")

        }
        if (res.status.code === StatusCode.FILES_SKIPPED) {
            props.setValidationMsg("No data to be provided")
            setValidationMsgStyle(classes.blackText)

        }
        setSummary(res['validationSummary']);
        setFilePath(res.filePath);
        const level3 = props.status.code === "VALIDATION_IN_PROGRESS" && props.status.context === "CATEGORY_STEPS";
        props.setLoading(level3 || props.statusLV4)
        props.setLoadingLevel3(level3)
    }, [props.status, props.statusLV4])

    const updateLevel4Button = (validationResult) => {
        const categories = props.categoriesProps
            .map(e => e['validationDTO'])
            .filter(j => j.id !== validationResult.id);
        categories.push(validationResult)
        props.setValidationButtonDisabled(isLevel4ButtonDisabled(categories))
    }

    useEffect(() => {
        if (props.isFileUploaded) {
            runValidation().then();
        }
    }, [props.isFileUploaded]);

    const runValidation = async () => {
        props.setLoading(true);
        props.setLoadingLevel3(true);
        return await validateCategory({
            "modelPath": props.modelPath,
            "categoryName": props.categoryDetails['categoryDTO'].name,
            "dataCollection": props.dataCollection,
            "marketNode": props.marketNode,
            "validationId": props.categoryDetails['validationDTO'].id,
            "sheetsName": props.categoryDetails['categoryDTO'].sheets.map(s => s.name),
            "excelFilePath": props.filePath
        }).then(res => {
            if (res?.content?.status?.code !== undefined
                && res?.content?.status?.code !== 'VALIDATION_IN_PROGRESS') {
                if(res.status === 200) {
                    showValidationSuccess(res)
                } else if(res.status !== 202) {
                    showValidationFailed()
                }
                props.setLoading(false);
                props.setLoadingLevel3(false);
                props.onFileUpload(false);
                props.setUploadBtnDisabled(false);
            }
        });
    }


    const showValidationSuccess = (res) => {
        updateLevel4Button(res.content)
        setFilePath(res.content.filePath);
        setSummary(res.content['validationSummary']);
        props.setStatus(res.content.status);
        showSuccess("Success", `Validation is completed.`);
    }
    const headerTitle = "Validation details for " + props.marketNode.name + " - " + props.categoryDetails['categoryDTO'].name;
    const footer = () => {
        return (
            <>
                <div className={classes.flexDisplay}>
                    <div className={`col flex align-items-center justify-content-end m-0 p-0`}
                         style={{height: "2.5em"}}>
                        <Button
                            icon={refreshIcon}
                            onClick={getValidationStatus}
                            text
                            style={{color: '#487c84', width: "2.7em"}}
                        />
                    </div>
                </div>
            </>
        )
    }
    const showValidationFailed = async () => {
        setFilePath(null);
        await findValidationResultDetails(props.validationResut.id)
            .then(res => {
                if (res.status === 200) {
                    updateLevel4Button(res.content)
                    props.setStatus(res.content.status)
                } else {
                    showError('Error', "A problem was occurred when searching category status")
                }
            })
    }

    const getValidationProgressColor = (status) => {
        if (status === "SUCCESS") {
            return "#3f9c35";
        }
        if (status === "IN_PROGRESS") {
            return "orange";
        }
        if (status === "FAILED") {
            return "red";
        }
        if (status === "NOT_YET_STARTED") {
            return "grey";
        }
        if (status === "SHEET_NOT_FOUND") {
            return "red";
        }
    }

    function showValidationDetails() {
        getValidationStatus();
        setIsPopupValidationDetailsVisible(true);

    }

    function hidePopup() {
        setIsPopupValidationDetailsVisible(false);
    }


    function getValidationStatus() {
        setRefreshIcon("pi pi-spin pi-sync")
        getValidationProgressStatus(props.dataCollection.name, props.categoryDetails['categoryDTO'].name, props.marketNode.name).then(r => {
            if (r.content != null) {
                setSheetList(r.content);
            } else {
                showError("Error", `An error occurs when getting the validation status.`);
            }
            setRefreshIcon("pi pi-sync")
        });
    }

    return (
        <div className="flex align-items-center justify-content-center">
            <div className="grid">
                <div className="col-12 flex align-items-center justify-content-center">
                    <span>
                    <label className={classes.title}>Data validation</label>

                        {validationWaiting ?
                            <><Tooltip target=".disabled-button"/>
                            <span className="disabled-button" data-pr-tooltip="Server busy, please wait">
                            <Button icon="pi pi-info-circle"
                                    visible={props.loadingLevel3}
                                    className={classes.iconButton + " p-button-rounded-sm p-button-text"}
                                    size="small"
                                    disabled
                                    style={{color: "#487c84"}}
                            /> </span></> : <Button icon="pi pi-external-link"
                                                 visible={props.loadingLevel3}
                                                 tooltip="Consult validation details"
                                                 className={classes.iconButton + " p-button-rounded-sm p-button-text"}
                                                 onClick={showValidationDetails}
                                                 size="small"
                                                 style={{color: "#487c84"}}
                            />}
                </span>
                </div>
                <div className="col-12 flex align-items-center justify-content-center">
                    {props.loading
                        ? <LoadingSpinner validationId={props.categoryDetails.id}
                                          crossValidationMsg={props.crossValidationMsg}
                                          onFileUpload={props.onFileUpload}
                                          setUploadBtnDisabled={props.setUploadBtnDisabled}
                                          setLoading={p => props.setLoading(p)}
                                          setLoadingLevel3={props.setLoadingLevel3}
                                          setFilePath={p => setFilePath(p)}
                                          setSummary={s => setSummary(s)}
                                          statusLevel4={props.statusLV4}
                                          setStatus={s => setStatus(s)}/>
                        : <ValidationReport filePath={filePath}
                                            btnStyle={btnStyle}
                                            status={status}
                                            summary={summary}
                                            validationMsgStyle={validationMsgStyle}
                                            validationMsg={props.validationMsg}
                        />}
                </div>
            </div>
            <Dialog header={headerTitle} footer={footer()} visible={isPopupValidationDetailsVisible} onHide={hidePopup}
                    modal
                    style={{width: '50vw'}}>
                <div className={classes.flexDisplay} style={{backgroundColor: "#D8E2E5"}}>
                    <div className={`col-5 flex align-items-center justify-content-left m-0 p-0`}
                         style={{height: "2.5em"}}>
                        <h3 className={classes.firstCol}>Sheet</h3>
                    </div>
                    <div className={`col flex align-items-center justify-content-center m-0 p-0`}
                         style={{height: "2.5em"}}>
                        <h3>Extraction</h3>
                    </div>
                    <div className={`col flex align-items-center justify-content-center m-0 p-0`}
                         style={{height: "2.5em"}}>
                        <h3>Validation</h3>
                    </div>
                    <div className={`col-3 flex align-items-center justify-content-center m-0 p-0`}
                         style={{height: "2.5em"}}>
                        <h3>Result</h3>
                    </div>
                </div>
                <div>
                    {sheetList.map((x) => (
                        <Aux key={x.sheetsName}>
                            <div className={classes.flexDisplay}>
                                <div className={`col-5 flex align-items-center justify-content-left m-0 p-0`}
                                     style={{height: "2.5em"}}>
                                    <h4 className={classes.firstCol}> {x.sheetName}</h4>
                                </div>
                                <div className={`col flex align-items-center justify-content-center m-0 p-0`}
                                     style={{height: "2.5em"}}>
                                <span>
                      <i className="pi pi-circle-fill"
                         style={{'fontSize': '.8em', 'color': getValidationProgressColor(x.extractionStatus)}}></i>

                  </span></div>
                                <div className={`col flex align-items-center justify-content-center m-0 p-0`}
                                     style={{height: "2.5em"}}>
                                <span>
                      <i className="pi pi-circle-fill"
                         style={{'fontSize': '.8em', 'color': getValidationProgressColor(x.validationStatus)}}></i>

                  </span>
                                </div>
                                <div
                                    className={`col-3 flex align-items-center justify-content-center border-round m-0 p-0`}
                                    style={{height: "2.5em"}}>
                                    <h4>{x.validationStatus === "SHEET_NOT_FOUND" ? "Sheet not found" : x.nbError + " Error(s)"}</h4>
                                </div>
                            </div>
                            <Divider style={{height: "0.01em", margin: "0"}}/>
                        </Aux>
                    ))
                    }
                </div>
            </Dialog>

        </div>

    )
}

export default DataValidation;
