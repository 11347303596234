import React, {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {exportQueryResultAsCSV} from "../../../../../../../api/file/download-service";
import keycloak from "../../../../../../../keycloak";
import {
    deleteQueryExecution,
    executeSparqlQueries,
    retrieveSparqlQueries
} from "../../../../../../../api/dataCollection/sparql-queries-service";
import {ConfirmDialog} from "primereact/confirmdialog";
import classes from "../../../../../../PemmdbVersion/PemmdbVersionsControl.module.css"

const ActionButtonsTemplate = (props) => {
    const [execution, setExecution] = useState({...props.rowData});
    const [executionInProgress, setExecutionInProgress] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadDisabled, setDownloadDisabled] = useState(false);
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setExecution(props.rowData)
        setDownloadLoading(props.rowData.status.code === "EXECUTION_IN_PROGRESS");
        setDownloadDisabled(props.rowData['resultPath'] === "")
    }, [props.rowData])

    useEffect(() => {
        setInterval(async () => {
            if (executionInProgress) {
                await retrieveSparqlQueries(props.dcId)
                    .then(res => {
                        if (res.status === 200) {
                            let exec = res.content
                                .filter(e => e.queryId === execution.queryId)
                                .shift().executions.filter(e => e.id === execution.id)
                                .shift();
                            if (exec.status.code !== "EXECUTION_IN_PROGRESS") {
                                setExecution(exec);
                                setExecutionInProgress(false);
                            }
                        }

                    })
            }
        }, 60000)
    }, [executionInProgress])

    const downloadResult = () => {
        setDownloadLoading(true);
        exportQueryResultAsCSV(props.queryName.replaceAll(' ', '_'), props.rowData['resultPath'])
            .then(_ => setDownloadLoading(false));
    }

    const confirmDeleteQuery = () => {
        setVisible(true);

    }
    const executeQuery = async () => {
        setDownloadLoading(true);
        await executeSparqlQueries({
            "queryId": execution.queryId,
            "id": execution.id,
            "executionName": execution.name,
            "executedBy": keycloak.tokenParsed.name,
            "sparqlParameters": execution.parameters,
            "marketNodes": execution.marketNodes,
            "sheets": execution.sheets
        }).then(res => {
            if (res.status === 200) {
                setExecution({...res.content});
                if (res.content.status.code === "EXECUTION_IN_PROGRESS") {
                    setExecutionInProgress(true)
                }
            } else {
                setExecution({...execution});
            }

            setDownloadLoading(false);
        });
    }
    const accept = async () => {
        props.setIdDeleting([execution.id, ...props.idDeleting]);
        deleteQueryExecution(execution.id).then(r => {
            if (r.status === 200) {

               props.setExecutions(props.executions.filter(x => x.id !== execution.id));

            }
        })


    }

    const reject = () => {
    }
    return (<div >
        <Button
            type={"button"}
            style={{color: '#487c84'}}
            className=" p-button-rounded-sm p-button-text"
            icon={"pi pi-download"}
            tooltip={"Download result"}
            onClick={downloadResult}
            loading={downloadLoading}
            disabled={downloadDisabled}
        />
        <Button
            type={"button"}
            style={{color: '#487c84'}}
            className=" p-button-rounded-sm p-button-text"
            icon={"pi pi-trash"}
            tooltip={"Delete execution"}
            onClick={confirmDeleteQuery}


        />
        <Button
            type={"button"}
            style={{color: '#487c84'}}
            className=" p-button-rounded-sm p-button-text"
            icon={"pi pi-replay"}
            tooltip={"Rerun"}
            onClick={executeQuery}
            disabled={downloadLoading}

        />
        <ConfirmDialog
                      visible={visible}
                      onHide={() => setVisible(false)}
                       message={"Are you sure you want to delete query execution  "+execution.name +" ?" }
                       header="Confirmation"
                       icon="pi pi-exclamation-triangle"
                      acceptClassName={classes.acceptDelete}
                      rejectClassName={classes.rejectDelete}
                       accept={accept}
                       reject={reject}/>
    </div>)
}
export default ActionButtonsTemplate;
